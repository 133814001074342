var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mb-8"},[_c('div',{staticClass:"flex items-center justify-between mb-2"},[_c('h3',{staticClass:"text-2xl"},[_vm._v(" Models Assigned ")]),(_vm.edit)?_c('vue-button',{attrs:{"icon":['fas', 'plus-circle'],"text":""},on:{"click":_vm.assignNewModels}}):_vm._e()],1),(_vm.models && _vm.models.length > 0)?_c('div',{staticClass:"flex flex-wrap w-row-offset -mx-2"},[_c('draggable',{staticClass:"w-full",attrs:{"disabled":!_vm.edit,"handle":".handle"},model:{value:(_vm.modelsSorted),callback:function ($$v) {_vm.modelsSorted=$$v},expression:"modelsSorted"}},_vm._l((_vm.modelsSorted),function(item){return _c('div',{key:item.id,staticClass:"w-full px-2 mb-2"},[_c('div',{staticClass:"group flex items-center justify-between bg-gray-200 rounded-sm px-2 py-2"},[_c('div',{staticClass:"space-x-2"},[(_vm.edit)?_c('span',{staticClass:"handle cursor-move px-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrows-alt-v'],"fa-fw":""}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getItemName(item)))]),(item.pivot && item.pivot.default)?_c('span',{staticClass:"uppercase font-bold text-xs"},[_vm._v(" (Default) ")]):_vm._e(),(_vm.modelType && _vm.modelType.selectAppend)?_c('span',{staticClass:"uppercase font-bold text-xs"},[_vm._v(" "+_vm._s(item[_vm.modelType.selectAppend])+" ")]):_vm._e()]),_c('div',{staticClass:"flex space-x-2"},[(_vm.edit && item.pivot && _vm.modelType.endpoint === 'assemblies')?_c('vue-select',{attrs:{"options":[
                {
                  label: 'Component Field | Visible | Selectable',
                  value: 0
                },
                {
                  label: 'Component Field | Visible | Read-only',
                  value: 2
                },
                {
                  label: 'Component Field | SG Series Only | Read-only',
                  value: 3
                },
                {
                  label: 'Bundle Field | Visible | Selectable',
                  value: 1
                },
                {
                  label: 'Hidden Field',
                  value: 100
                }
              ],"disable-on-null":false,"labelKey":"label","valueKey":"value"},model:{value:(item.pivot.group),callback:function ($$v) {_vm.$set(item.pivot, "group", $$v)},expression:"item.pivot.group"}}):_vm._e(),(_vm.edit)?_c('vue-button',{attrs:{"text":"","error":"","xs":"","icon":['fas', 'trash']},nativeOn:{"click":function($event){return _vm.removeModel(item.id)}}}):_vm._e()],1)])])}),0)],1):_c('div',{staticClass:"text-gray-600 text-sm py-4"},[_vm._v(" No models have been assigned yet. Click the edit button to add models. ")]),_c('vue-dialog',{attrs:{"dialog":_vm.form.modal},on:{"update:dialog":function($event){return _vm.$set(_vm.form, "modal", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Assign New Models ")]},proxy:true},{key:"action",fn:function(){return [_c('vue-button',{staticClass:"justify-center w-full",attrs:{"success":""},on:{"click":_vm.addModels}},[_vm._v(" Add Models ")])]},proxy:true}])},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex mb-2"},[_c('vue-input',{staticClass:"flex-grow",attrs:{"append":"","placeholder":"Search for models"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}}),_c('vue-button',{attrs:{"sm":"","append":""},on:{"click":_vm.searchModels}},[_vm._v(" Find ")])],1),(!_vm.form.loading && _vm.form.models.length > 0)?_c('div',{staticClass:"flex flex-col flex-grow overflow-y-auto my-2"},[_c('div',[_c('vue-checkbox-group',{attrs:{"data":_vm.form.models,"item-append":_vm.modelType.selectAppend,"label-key":_vm.modelType.selectLabelKey,"value-key":_vm.modelType.selectValueKey},model:{value:(_vm.form.new),callback:function ($$v) {_vm.$set(_vm.form, "new", $$v)},expression:"form.new"}})],1)]):(!_vm.form.loading)?_c('div',{staticClass:"my-2"},[_vm._v(" No results found ")]):_vm._e(),(_vm.form.loading)?_c('div',{staticClass:"my-2"},[_vm._v(" Loading... ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }