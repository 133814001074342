 <template>
  <section class="mb-8">
    <div class="flex items-center justify-between mb-2">
      <h3 class="text-2xl">
        Models Assigned
      </h3>
      <vue-button
        v-if="edit"
        :icon="['fas', 'plus-circle']"
        text
        @click="assignNewModels"
      />
    </div>
    <div v-if="models && models.length > 0" class="flex flex-wrap w-row-offset -mx-2">
      <draggable
        v-model="modelsSorted"
        :disabled="!edit"
        handle=".handle"
        class="w-full"
      >
        <div
          v-for="item in modelsSorted"
          :key="item.id"
          class="w-full px-2 mb-2"
        >
          <div class="group flex items-center justify-between bg-gray-200 rounded-sm px-2 py-2">
            <div class="space-x-2">
              <span v-if="edit" class="handle cursor-move px-2">
                <font-awesome-icon :icon="['fas', 'arrows-alt-v']" fa-fw />
              </span>
              <span>{{ getItemName(item) }}</span>
              <span v-if="item.pivot && item.pivot.default" class="uppercase font-bold text-xs">
                (Default)
              </span>
              <span v-if="modelType && modelType.selectAppend" class="uppercase font-bold text-xs">
                {{ item[modelType.selectAppend] }}
              </span>
            </div>
            <div class="flex space-x-2">
              <vue-select
                v-if="edit && item.pivot && modelType.endpoint === 'assemblies'"
                v-model="item.pivot.group"
                :options="[
                  {
                    label: 'Component Field | Visible | Selectable',
                    value: 0
                  },
                  {
                    label: 'Component Field | Visible | Read-only',
                    value: 2
                  },
                  {
                    label: 'Component Field | SG Series Only | Read-only',
                    value: 3
                  },
                  {
                    label: 'Bundle Field | Visible | Selectable',
                    value: 1
                  },
                  {
                    label: 'Hidden Field',
                    value: 100
                  }
                ]"
                :disable-on-null="false"
                labelKey="label"
                valueKey="value"
              />
              <vue-button
                v-if="edit"
                text
                error
                xs
                :icon="['fas', 'trash']"
                @click.native="removeModel(item.id)"
              />
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else class="text-gray-600 text-sm py-4">
      No models have been assigned yet. Click the edit button to add models.
    </div>
    <vue-dialog :dialog.sync="form.modal">
      <template #title>
        Assign New Models
      </template>
      <div class="flex flex-col">
        <div class="flex mb-2">
          <vue-input
            v-model="form.search"
            append
            placeholder="Search for models"
            class="flex-grow"
          />
          <vue-button
            sm
            append
            @click="searchModels"
          >
            Find
          </vue-button>
        </div>
        <div v-if="!form.loading && form.models.length > 0" class="flex flex-col flex-grow overflow-y-auto my-2">
          <div>
            <vue-checkbox-group
              v-model="form.new"
              :data="form.models"
              :item-append="modelType.selectAppend"
              :label-key="modelType.selectLabelKey"
              :value-key="modelType.selectValueKey"
            />
          </div>
        </div>
        <div v-else-if="!form.loading" class="my-2">
          No results found
        </div>
        <div v-if="form.loading" class="my-2">
          Loading...
        </div>
      </div>
      <template #action>
        <vue-button success class="justify-center w-full" @click="addModels">
          Add Models
        </vue-button>
      </template>
    </vue-dialog>
  </section>
</template>
<script>
import { intersectionBy, map, pullAt, findIndex } from 'lodash'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },

  props: {
    models: {
      type: [Boolean, Array],
      required: false,
      default: () => { return [] }
    },

    type: {
      type: String,
      required: true
    },

    filter: {
      type: String,
      required: false,
      default: () => { return null }
    },

    edit: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      sections: [],
      form: {
        loading: false,
        models: false,
        new: [],
        search: '',
        modal: false
      }
    }
  },

  computed: {
    modelsSorted: {
      get () {
        return this.models
      },
      set (value) {
        this.$emit('update:models', map(value, (item, index) => {
          item.pivot.order = index
          return item
        }))
      }
    },

    modelType () {
      let data = {}
      switch (this.type) {
        case 'App\\Models\\Assembly':
        case 'assembly':
          data = {
            endpoint: 'assemblies',
            selectLabelKey: 'name',
            selectValueKey: 'id'
          }
          break
        case 'App\\Models\\Product':
        case 'product':
          data = {
            endpoint: 'products',
            selectLabelKey: 'name',
            selectValueKey: 'id'
          }
          break
        case 'App\\Models\\Metadata':
        case 'metadata':
          data = {
            endpoint: 'metadata',
            selectLabelKey: 'key',
            selectValueKey: 'id',
            selectAppend: 'type'
          }
          break
        case 'App\\Models\\Menu':
        case 'menu':
          data = {
            endpoint: 'menus',
            selectLabelKey: 'name',
            selectValueKey: 'id'
          }
          break
      }
      return data
    }
  },

  watch: {
    'form.modal': function () {
      this.form.search = ''
      this.form.new = []
    }
  },

  methods: {
    assignNewModels () {
      this.form.search = ''
      this.getModels()
      this.form.modal = true
    },

    getModels () {
      this.form.loading = true
      this.$api.get(this.modelType.endpoint, {
        params: {
          type: this.filter,
          search: this.form.search,
          exclude: map(this.models, (model) => {
            return model.id
          })
        }
      })
        .then((res) => {
          this.form.models = res.data
        })
        .finally(() => {
          this.form.loading = false
        })
    },

    addModels () {
      this.$emit('update:models', [
        ...this.models,
        ...intersectionBy(this.form.models, this.form.new, 'id')
      ])
      this.form.new = []
      this.form.modal = false
    },

    removeModel (id) {
      pullAt(this.models, findIndex(this.models, { id }))
      this.$emit('update:models', this.models)
    },

    searchModels () {
      this.getModels()
    },

    getItemName (item) {
      let name

      if (item.name) {
        name = item.name
      } else if (item.value) {
        if (item.value.name) {
          name = item.value.name
        } else if (item.value.title) {
          name = item.value.title
        }
      } else if (item.key) {
        name = item.key
      }

      return !name ? '(No Name)' : name
    }
  }
}
</script>
