<template>
  <SingleModelLayout
    :model="model"
    :route-back="{
      name: 'sets.index'
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-if="modelType.images"
        :value="model.images ? model.images.logo : null"
        :edit="edit"
        :model="model"
        class="mb-4"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Logo"
        @update="syncImage($event, 'logo')"
        @discard="removeImage"
      />
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Key"
      />
    </template>
    <template #main>
      <models-assigned
        :models.sync="model[modelType.key]"
        :type="model.type"
        :edit="edit"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import ModelsAssigned from '@/components/ModelsAssigned'

export default {
  name: 'SetsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    ModelsAssigned
  },

  data () {
    return {
      loading: false,
      model: false,
      modelOld: false,
      files: [],
      edit: false,
      route: {
        apiEndpoint: 'sets',
        mediaEndpoint: 'set',
        index: 'sets.index'
      }
    }
  },

  computed: {
    modelType () {
      let data
      switch (this.model.type) {
        case 'App\\Models\\Assembly':
          data = {
            type: 'assembly',
            key: 'fields',
            images: true
          }
          break
        case 'App\\Models\\Product':
          data = {
            type: 'product',
            key: 'products',
            images: true
          }
          break
        case 'App\\Models\\Metadata':
          data = {
            key: 'metadata',
            images: false
          }
          break
        case 'App\\Models\\Menu':
          data = {
            key: 'menus',
            images: false
          }
          break
        default:
          data = {
            key: 'models',
            images: false
          }
      }
      return data
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withImages: true,
          withPivot: true,
          with: ['menus', 'metadata', 'assemblies', 'products', 'products.parts']
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.saveFiles()
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, this.model)
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage () {
      this.files = false
    },

    saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('model', 'Set')
          formData.append('collection', file.collection)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
